import { MergeWhitelabelConfig } from "../default";
import { PartnerConfig } from "../partner.type";

export const config: PartnerConfig = {
  // Unique identifier for partner
  key: "neredennereye",
  // Query string value used to identify a whitelabel
  whitelabel: "neredennereye",
  display_name: "NeredenNereye.com",
  // Settings used to determine whitelabel behavior
  // IMPORTANT: This will be also be sent to the browser,
  // so do not use it to store any secrets
  whitelabel_settings: MergeWhitelabelConfig({
    // forwarded-host:napi-host
    napi_hosts: {
      // Prod
      "www.neredennereye.com": "napi.neredennereye.com",
      // Preview
      "www.preview.neredennereye.com": "napi-preview.neredennereye.com",
      // Dev
      "dev.neredennereye.com": "napi-preview.neredennereye.com"
    },
    features_override: {
      ADULT_ONLY_SEARCH: true,
      ASYNC_CART_SHOULD_HIDE_FEES: true,
      ASYNC_CART_SHOULD_HIDE_SUBTOTAL: true,
      ASYNC_CART_SHOULD_HIDE_TAXES: true,
      AUTO_SELECT_ID_TYPE: ["turkish_identification_number"],
      CANCELLATION_WITHOUT_ACCOUNT_ENABLED: true,
      CHECKOUT_SEAT_SELECTION_V2_SHOULD_SHOW_SEAT_SELECTOR_DRAWER_TITLE: false,
      CHECKOUT_SHOULD_HIDE_ANCILLARIES: true,
      CHECKOUT_SHOULD_HIDE_PHONE_TOOLTIP: true,
      CHECKOUT_SHOULD_HIDE_PRICE_SUBTOTAL: true,
      CHECKOUT_SHOULD_HIDE_PRICE_TAXES_INFORMATIONS: true,
      CHECKOUT_SHOULD_HIDE_PRICE_WITH_PLATFORM_FEE: true,
      CHECKOUT_SHOULD_SHOW_PASSENGER_QUESTION_CONTACT_INFORMATION: true,
      CHECKOUT_SHOULD_SHOW_SPECIFIC_TERMS_CONTENT: true,
      CHECKOUT_PASSENGER_SUBHEADER_INFO: "none",
      DISPLAY_NAME: "NeredenNereye.com",
      ERROR_SHOULD_SHOW_FOOTER_SIMPLIFIED: true,
      LANDING_PAGES_SHOULD_SHOW_AFFILIATE_CHECKBOX: false,
      PURCHASE_CONFIRMATION_SHOULD_HIDE_ACCESS_TO_BOOKING_MANAGER: true,
      PURCHASE_CONFIRMATION_SHOULD_SHOW_RETURN_LINK: true,
      PURCHASE_CONFIRMATION_SHOULD_SHOW_TRANSACTION_SUMMARY: false,
      RESULTS_SHOULD_HIDE_ALERT_DEPARTURE_DURING_THE_NIGHT: false,
      RESULTS_SHOULD_HIDE_SEAT_SELECTION_LEGEND: false,
      RESULTS_SHOULD_HIDE_SEAT_SELECTION_TRIP_SUMMARY: false,
      RESULTS_SHOW_FILTERS: false,
      RESULTS_SHOULD_SHOW_PRICE_PER_PASSENGER: true,
      RESULTS_SHOULD_SHOW_SEAT_SELECTOR_DRAWER: true,
      RESULTS_TOOLBAR_SHOULD_SHOW_QUICK_FILTER: true,
      SHOULD_HIDE_MOBILE_APPS_PROMOTION: true
    },
    partner_favicons: true,
    utm_home: {
      utm_source: "neredennereye",
      utm_medium: "cpa",
      // Use `String.replace()` to inject locale
      utm_campaign: "partner-neredennereye-tab-{locale}-home"
    },
    utm_checkout: {
      utm_source: "neredennereye",
      utm_medium: "cpa",
      utm_campaign: "partner-neredennereye-whitelabel-{locale}-checkout"
    },
    currency: "TRY"
  }),
  // Map all of partner's locales to a Busbud locale, when available
  // If no matching locale, will fallback to default locale
  locale_mapping: {
    "tr-TR": "tr"
  },
  fallback_locale: "tr",
  webpack_config: {
    theme_import: () =>
      import(
        /* webpackChunkName: "theme-neredennereye" */
        "@busbud/design-system-components/dist/themes/neredennereye"
      ),
    supported_locales: ["tr"]
  },
  referral_banner: {
    color: "#e20916",
    name: "Nereden Nereye",
    logo: "https://www.neredennereye.com/images/neredennereye-logo.jpg"
  }
};
