"use strict";

const link_templates = {
  home: "/{locale}",
  about: "/{locale}/about",
  about_partners: "/{locale}/about/partners",
  terms_of_use: "/{locale}/terms-of-use/",
  privacy: "/{locale}/privacy-policy/",
  refunds: "/{locale}/refund-policy/",
  press: "/{locale}/about/press",
  careers: "/{locale}/careers/",
  bus_partners: "/partner/supply/",
  impressum: "/{locale}/about/impressum",
  instagram: "https://www.instagram.com/busbud/",
  facebook: "https://www.facebook.com/Busbud",
  twitter: "https://twitter.com/Busbud",
  affiliate_products: {
    // TODO: affiliate products are not completely localized
    de: "https://www.busbud.com/partner",
    en: "https://www.busbud.com/partner",
    "en-ca": "https://www.busbud.com/partner",
    "en-gb": "https://www.busbud.com/partner",
    es: "https://www.busbud.com/partner/es/",
    "es-419": "https://www.busbud.com/partner",
    "es-mx": "https://www.busbud.com/partner",
    fr: "https://www.busbud.com/partner/fr/",
    "fr-ca": "https://www.busbud.com/partner/fr/",
    it: "https://www.busbud.com/partner",
    nl: "https://www.busbud.com/partner",
    pl: "https://www.busbud.com/partner",
    pt: "https://www.busbud.com/partner",
    "pt-pt": "https://www.busbud.com/partner",
    ru: "https://www.busbud.com/partner",
    sv: "https://www.busbud.com/partner",
    tr: "https://www.busbud.com/partner",
    zh: "https://www.busbud.com/partner"
  },
  contact: "/{locale}/contact",
  blog: {
    // TODO: Blog is not completely localized
    de: "https://www.busbud.com/blog/",
    en: "https://www.busbud.com/blog/",
    "en-ca": "https://www.busbud.com/blog/",
    "en-gb": "https://www.busbud.com/blog/",
    es: "https://www.busbud.com/blog/es/pagina-principal/",
    "es-419": "https://www.busbud.com/blog/es/pagina-principal/",
    "es-mx": "https://www.busbud.com/blog/es/pagina-principal/",
    fr: "https://www.busbud.com/blog/fr/accueil/",
    "fr-ca": "https://www.busbud.com/blog/fr/accueil/",
    it: "https://www.busbud.com/blog/",
    nl: "https://www.busbud.com/blog/",
    pl: "https://www.busbud.com/blog/",
    pt: "https://www.busbud.com/blog/pagina-inicial/",
    "pt-pt": "https://www.busbud.com/blog/pagina-inicial/",
    ru: "https://www.busbud.com/blog/",
    sv: "https://www.busbud.com/blog/",
    tr: "https://www.busbud.com/blog/",
    zh: "https://www.busbud.com/blog/"
  },
  stop_keyword: {
    de: "/de/{keyword}/s/{id}",
    en: "/en/{keyword}/s/{id}",
    "en-ca": "/en-ca/{keyword}/s/{id}",
    "en-gb": "/en-gb/{keyword}/s/{id}",
    es: "/es/{keyword}/s/{id}",
    "es-419": "/es-419/{keyword}/s/{id}",
    "es-mx": "/es-mx/{keyword}/s/{id}",
    fr: "/fr/{keyword}/s/{id}",
    "fr-ca": "/fr-ca/{keyword}/s/{id}",
    it: "/it/{keyword}/s/{id}",
    nl: "/nl/{keyword}/s/{id}",
    pl: "/pl/{keyword}/s/{id}",
    pt: "/pt/{keyword}/s/{id}",
    "pt-pt": "/pt-pt/{keyword}/s/{id}",
    ru: "/ru/{keyword}/s/{id}",
    sv: "/sv/{keyword}/s/{id}",
    tr: "/tr/{keyword}/s/{id}",
    zh: "/zh/{keyword}/s/{id}"
  },
  partner: {
    de: "/de/busunternehmen/{url_form}",
    en: "/en/bus-company/{url_form}",
    "en-ca": "/en-ca/bus-company/{url_form}",
    "en-gb": "/en-gb/bus-company/{url_form}",
    es: "/es/empresa-de-autobuses/{url_form}",
    "es-419": "/es-419/empresa-de-autobuses/{url_form}",
    "es-mx": "/es-mx/empresa-de-autobuses/{url_form}",
    fr: "/fr/compagnie-de-bus/{url_form}",
    "fr-ca": "/fr-ca/compagnie-de-bus/{url_form}",
    it: "/it/operatore-di-autobus/{url_form}",
    nl: "/nl/busmaatschappij/{url_form}",
    pl: "/pl/firma-autobusowa/{url_form}",
    pt: "/pt/empresa-de-onibus/{url_form}",
    "pt-pt": "/pt-pt/empresa-de-autocarros/{url_form}",
    sv: "/sv/bussbolag/{url_form}",
    ru: "/ru/avtobusnaya-kompaniya/{url_form}",
    tr: "/tr/otobus-firmalari/{url_form}",
    zh: "/zh/bus-company/{url_form}"
  },
  operator: "/{locale}/o/{url_form}",
  operator_awards: "/{language}/operator-awards",
  results: {
    de: "/de/bus-schedules-results/{origin_geohash}/{destination_geohash}",
    en: "/en/bus-schedules-results/{origin_geohash}/{destination_geohash}",
    "en-ca":
      "/en-ca/bus-schedules-results/{origin_geohash}/{destination_geohash}",
    "en-gb":
      "/en-gb/bus-schedules-results/{origin_geohash}/{destination_geohash}",
    es: "/es/bus-schedules-results/{origin_geohash}/{destination_geohash}",
    "es-419":
      "/es-419/bus-schedules-results/{origin_geohash}/{destination_geohash}",
    "es-mx":
      "/es-mx/bus-schedules-results/{origin_geohash}/{destination_geohash}",
    fr: "/fr/bus-schedules-results/{origin_geohash}/{destination_geohash}",
    "fr-ca":
      "/fr-ca/bus-schedules-results/{origin_geohash}/{destination_geohash}",
    it: "/it/bus-schedules-results/{origin_geohash}/{destination_geohash}",
    nl: "/nl/bus-schedules-results/{origin_geohash}/{destination_geohash}",
    pl: "/pl/bus-schedules-results/{origin_geohash}/{destination_geohash}",
    pt: "/pt/bus-schedules-results/{origin_geohash}/{destination_geohash}",
    "pt-pt":
      "/pt-pt/bus-schedules-results/{origin_geohash}/{destination_geohash}",
    ru: "/ru/bus-schedules-results/{origin_geohash}/{destination_geohash}",
    sv: "/sv/bus-schedules-results/{origin_geohash}/{destination_geohash}",
    tr: "/tr/bus-schedules-results/{origin_geohash}/{destination_geohash}",
    zh: "/zh/bus-schedules-results/{origin_geohash}/{destination_geohash}"
  },
  deeplink: {
    de: "/de/deeplink/{origin_geohash}/{destination_geohash}/{outbound_id}/{return_id}",
    en: "/en/deeplink/{origin_geohash}/{destination_geohash}/{outbound_id}/{return_id}",
    "en-ca":
      "/en-ca/deeplink/{origin_geohash}/{destination_geohash}/{outbound_id}/{return_id}",
    "en-gb":
      "/en-gb/deeplink/{origin_geohash}/{destination_geohash}/{outbound_id}/{return_id}",
    es: "/es/deeplink/{origin_geohash}/{destination_geohash}/{outbound_id}/{return_id}",
    "es-419":
      "/es-419/deeplink/{origin_geohash}/{destination_geohash}/{outbound_id}/{return_id}",
    "es-mx":
      "/es-mx/deeplink/{origin_geohash}/{destination_geohash}/{outbound_id}/{return_id}",
    fr: "/fr/deeplink/{origin_geohash}/{destination_geohash}/{outbound_id}/{return_id}",
    "fr-ca":
      "/fr-ca/deeplink/{origin_geohash}/{destination_geohash}/{outbound_id}/{return_id}",
    it: "/it/deeplink/{origin_geohash}/{destination_geohash}/{outbound_id}/{return_id}",
    nl: "/nl/deeplink/{origin_geohash}/{destination_geohash}/{outbound_id}/{return_id}",
    pl: "/pl/deeplink/{origin_geohash}/{destination_geohash}/{outbound_id}/{return_id}",
    pt: "/pt/deeplink/{origin_geohash}/{destination_geohash}/{outbound_id}/{return_id}",
    "pt-pt":
      "/pt-pt/deeplink/{origin_geohash}/{destination_geohash}/{outbound_id}/{return_id}",
    ru: "/ru/deeplink/{origin_geohash}/{destination_geohash}/{outbound_id}/{return_id}",
    sv: "/sv/deeplink/{origin_geohash}/{destination_geohash}/{outbound_id}/{return_id}",
    tr: "/tr/deeplink/{origin_geohash}/{destination_geohash}/{outbound_id}/{return_id}",
    zh: "/zh/deeplink/{origin_geohash}/{destination_geohash}/{outbound_id}/{return_id}"
  },
  route: {
    de: "/de/bus-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}",
    en: "/en/bus-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}",
    "en-ca":
      "/en-ca/bus-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}",
    "en-gb":
      "/en-gb/bus-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}",
    es: "/es/autobus-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}",
    "es-419":
      "/es-419/autobus-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}",
    "es-mx":
      "/es-mx/autobus-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}",
    fr: "/fr/bus-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}",
    "fr-ca":
      "/fr-ca/bus-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}",
    it: "/it/bus-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}",
    nl: "/nl/bus-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}",
    pl: "/pl/autobus-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}",
    pt: "/pt/onibus-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}",
    "pt-pt":
      "/pt-pt/autocarro-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}",
    sv: "/sv/buss-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}",
    ru: "/ru/avtobus-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}",
    tr: "/tr/otobus-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}",
    zh: "/zh/bus-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}"
  },
  route_sem: {
    de: "/de/bus-{origin_name}-{destination_name}/r-sem/{origin_geohash}-{destination_geohash}",
    en: "/en/bus-{origin_name}-{destination_name}/r-sem/{origin_geohash}-{destination_geohash}",
    "en-ca":
      "/en-ca/bus-{origin_name}-{destination_name}/r-sem/{origin_geohash}-{destination_geohash}",
    "en-gb":
      "/en-gb/bus-{origin_name}-{destination_name}/r-sem/{origin_geohash}-{destination_geohash}",
    es: "/es/autobus-{origin_name}-{destination_name}/r-sem/{origin_geohash}-{destination_geohash}",
    "es-419":
      "/es-419/autobus-{origin_name}-{destination_name}/r-sem/{origin_geohash}-{destination_geohash}",
    "es-mx":
      "/es-mx/autobus-{origin_name}-{destination_name}/r-sem/{origin_geohash}-{destination_geohash}",
    fr: "/fr/bus-{origin_name}-{destination_name}/r-sem/{origin_geohash}-{destination_geohash}",
    "fr-ca":
      "/fr-ca/bus-{origin_name}-{destination_name}/r-sem/{origin_geohash}-{destination_geohash}",
    it: "/it/bus-{origin_name}-{destination_name}/r-sem/{origin_geohash}-{destination_geohash}",
    nl: "/nl/bus-{origin_name}-{destination_name}/r-sem/{origin_geohash}-{destination_geohash}",
    pl: "/pl/autobus-{origin_name}-{destination_name}/r-sem/{origin_geohash}-{destination_geohash}",
    pt: "/pt/onibus-{origin_name}-{destination_name}/r-sem/{origin_geohash}-{destination_geohash}",
    "pt-pt":
      "/pt-pt/autocarro-{origin_name}-{destination_name}/r-sem/{origin_geohash}-{destination_geohash}",
    sv: "/sv/buss-{origin_name}-{destination_name}/r-sem/{origin_geohash}-{destination_geohash}",
    ru: "/ru/avtobus-{origin_name}-{destination_name}/r-sem/{origin_geohash}-{destination_geohash}",
    tr: "/tr/otobus-{origin_name}-{destination_name}/r-sem/{origin_geohash}-{destination_geohash}",
    zh: "/zh/bus-{origin_name}-{destination_name}/r-sem/{origin_geohash}-{destination_geohash}"
  },
  point_of_interest: {
    de: "/de/poi/{keyword}/{id}",
    en: "/en/poi/{keyword}/{id}",
    "en-ca": "/en-ca/poi/{keyword}/{id}",
    "en-gb": "/en-gb/poi/{keyword}/{id}",
    es: "/es/poi/{keyword}/{id}",
    "es-419": "/es-419/poi/{keyword}/{id}",
    "es-mx": "/es-mx/poi/{keyword}/{id}",
    fr: "/fr/poi/{keyword}/{id}",
    "fr-ca": "/fr-ca/poi/{keyword}/{id}",
    it: "/it/poi/{keyword}/{id}",
    nl: "/nl/poi/{keyword}/{id}",
    pl: "/pl/poi/{keyword}/{id}",
    pt: "/pt/poi/{keyword}/{id}",
    "pt-pt": "/pt-pt/poi/{keyword}/{id}",
    sv: "/sv/poi/{keyword}/{id}",
    ru: "/ru/poi/{keyword}/{id}",
    tr: "/tr/poi/{keyword}/{id}",
    zh: "/zh/poi/{keyword}/{id}"
  },
  point_of_interest_route: {
    de: "/de/bus-{origin_name}-{destination_name}/i/{origin}-{destination}",
    en: "/en/bus-{origin_name}-{destination_name}/i/{origin}-{destination}",
    "en-ca":
      "/en-ca/bus-{origin_name}-{destination_name}/i/{origin}-{destination}",
    "en-gb":
      "/en-gb/bus-{origin_name}-{destination_name}/i/{origin}-{destination}",
    es: "/es/autobus-{origin_name}-{destination_name}/i/{origin}-{destination}",
    "es-419":
      "/es-419/autobus-{origin_name}-{destination_name}/i/{origin}-{destination}",
    "es-mx":
      "/es-mx/autobus-{origin_name}-{destination_name}/i/{origin}-{destination}",
    fr: "/fr/bus-{origin_name}-{destination_name}/i/{origin}-{destination}",
    "fr-ca":
      "/fr-ca/bus-{origin_name}-{destination_name}/i/{origin}-{destination}",
    it: "/it/bus-{origin_name}-{destination_name}/i/{origin}-{destination}",
    nl: "/nl/bus-{origin_name}-{destination_name}/i/{origin}-{destination}",
    pl: "/pl/autobus-{origin_name}-{destination_name}/i/{origin}-{destination}",
    pt: "/pt/onibus-{origin_name}-{destination_name}/i/{origin}-{destination}",
    "pt-pt":
      "/pt-pt/autocarro-{origin_name}-{destination_name}/i/{origin}-{destination}",
    sv: "/sv/buss-{origin_name}-{destination_name}/i/{origin}-{destination}",
    ru: "/ru/avtobus-{origin_name}-{destination_name}/i/{origin}-{destination}",
    tr: "/tr/otobus-{origin_name}-{destination_name}/i/{origin}-{destination}",
    zh: "/zh/bus-{origin_name}-{destination_name}/i/{origin}-{destination}"
  },
  multimodal_route: {
    de: "/de/{origin_name}-{destination_name}/m/{origin}-{destination}",
    en: "/en/{origin_name}-{destination_name}/m/{origin}-{destination}",
    "en-ca": "/en-ca/{origin_name}-{destination_name}/m/{origin}-{destination}",
    "en-gb": "/en-gb/{origin_name}-{destination_name}/m/{origin}-{destination}",
    es: "/es/{origin_name}-{destination_name}/m/{origin}-{destination}",
    "es-419":
      "/es-419/{origin_name}-{destination_name}/m/{origin}-{destination}",
    "es-mx": "/es-mx/{origin_name}-{destination_name}/m/{origin}-{destination}",
    fr: "/fr/{origin_name}-{destination_name}/m/{origin}-{destination}",
    "fr-ca": "/fr-ca/{origin_name}-{destination_name}/m/{origin}-{destination}",
    it: "/it/{origin_name}-{destination_name}/m/{origin}-{destination}",
    nl: "/nl/{origin_name}-{destination_name}/m/{origin}-{destination}",
    pl: "/pl/{origin_name}-{destination_name}/m/{origin}-{destination}",
    pt: "/pt/{origin_name}-{destination_name}/m/{origin}-{destination}",
    "pt-pt": "/pt-pt/{origin_name}-{destination_name}/m/{origin}-{destination}",
    sv: "/sv/{origin_name}-{destination_name}/m/{origin}-{destination}",
    ru: "/ru/{origin_name}-{destination_name}/m/{origin}-{destination}",
    tr: "/tr/{origin_name}-{destination_name}/m/{origin}-{destination}",
    zh: "/zh/{origin_name}-{destination_name}/m/{origin}-{destination}"
  },
  train_route_keyword: {
    de: "/de/zug-{origin_name}-{destination_name}/t/{origin_geohash}-{destination_geohash}",
    en: "/en/train-{origin_name}-{destination_name}/t/{origin_geohash}-{destination_geohash}",
    "en-ca":
      "/en-ca/train-{origin_name}-{destination_name}/t/{origin_geohash}-{destination_geohash}",
    "en-gb":
      "/en-gb/train-{origin_name}-{destination_name}/t/{origin_geohash}-{destination_geohash}",
    es: "/es/tren-{origin_name}-{destination_name}/t/{origin_geohash}-{destination_geohash}",
    "es-419":
      "/es-419/tren-{origin_name}-{destination_name}/t/{origin_geohash}-{destination_geohash}",
    "es-mx":
      "/es-mx/tren-{origin_name}-{destination_name}/t/{origin_geohash}-{destination_geohash}",
    fr: "/fr/train-{origin_name}-{destination_name}/t/{origin_geohash}-{destination_geohash}",
    "fr-ca":
      "/fr-ca/train-{origin_name}-{destination_name}/t/{origin_geohash}-{destination_geohash}",
    it: "/it/treno-{origin_name}-{destination_name}/t/{origin_geohash}-{destination_geohash}",
    nl: "/nl/trein-{origin_name}-{destination_name}/t/{origin_geohash}-{destination_geohash}",
    pl: "/pl/pociag-{origin_name}-{destination_name}/t/{origin_geohash}-{destination_geohash}",
    pt: "/pt/trem-{origin_name}-{destination_name}/t/{origin_geohash}-{destination_geohash}",
    "pt-pt":
      "/pt-pt/trem-{origin_name}-{destination_name}/t/{origin_geohash}-{destination_geohash}",
    ru: "/ru/poezd-{origin_name}-{destination_name}/t/{origin_geohash}-{destination_geohash}",
    sv: "/sv/vlak-{origin_name}-{destination_name}/t/{origin_geohash}-{destination_geohash}",
    tr: "/tr/tren-{origin_name}-{destination_name}/t/{origin_geohash}-{destination_geohash}",
    zh: "/zh/train-{origin_name}-{destination_name}/t/{origin_geohash}-{destination_geohash}"
  },
  bus_tickets_page: {
    de: "/de/bt/bahn-tickets",
    en: "/en/bt/bus-tickets",
    "en-ca": "/en-ca/bt/bus-tickets",
    "en-gb": "/en-gb/bt/bus-tickets",
    es: "/es/bt/billetes-de-autobus",
    "es-419": "/es-419/bt/billetes-de-autobus",
    "es-mx": "/es-mx/bt/billetes-de-autobus",
    fr: "/fr/bt/tickets-de-bus",
    "fr-ca": "/fr-ca/bt/tickets-de-bus",
    it: "/it/bt/bigles-di-autobus",
    nl: "/nl/bt/bus-tickets",
    pl: "/pl/bt/bilety-autobusowe",
    pt: "/pt/bt/passagens-de-onibus",
    "pt-pt": "/pt-pt/bt/bilhetes-de-autocarro",
    ru: "/ru/bt/bus-tickets",
    sv: "/sv/bt/bussbiljetter",
    tr: "/tr",
    zh: "/zh/bt/bus-tickets"
  },
  train_tickets_page: {
    de: "/de/tt/zug-tickets",
    en: "/en/tt/train-tickets",
    "en-ca": "/en-ca/tt/train-tickets",
    "en-gb": "/en-gb/tt/train-tickets",
    es: "/es/tt/billetes-de-tren",
    "es-419": "/es-419/tt/billetes-de-tren",
    "es-mx": "/es-mx/tt/billetes-de-tren",
    fr: "/fr/tt/tickets-de-train",
    "fr-ca": "/fr-ca/tt/tickets-de-train",
    it: "/it/tt/biglietti-di-treno",
    nl: "/nl/tt/trein-tickets",
    pl: "/pl/tt/bilety-pociagu",
    pt: "/pt/tt/passagens-de-trem",
    "pt-pt": "/pt-pt/tt/bilhetes-de-tren",
    ru: "/ru/tt/train-tickets",
    sv: "/sv/tt/tag-biljetter",
    tr: "/tr/tt/otobus-biletleri",
    zh: "/zh/tt/train-tickets"
  },
  route_androidapp: {
    de: "android-app://com.busbud.android/https/www.busbud.com/de/bus-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}",
    en: "android-app://com.busbud.android/https/www.busbud.com/en/bus-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}",
    "en-ca":
      "android-app://com.busbud.android/https/www.busbud.com/en-ca/bus-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}",
    "en-gb":
      "android-app://com.busbud.android/https/www.busbud.com/en-gb/bus-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}",
    es: "android-app://com.busbud.android/https/www.busbud.com/es/autobus-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}",
    "es-419":
      "android-app://com.busbud.android/https/www.busbud.com/es-419/autobus-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}",
    "es-mx":
      "android-app://com.busbud.android/https/www.busbud.com/es-mx/autobus-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}",
    fr: "android-app://com.busbud.android/https/www.busbud.com/fr/bus-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}",
    "fr-ca":
      "android-app://com.busbud.android/https/www.busbud.com/fr-ca/bus-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}",
    it: "android-app://com.busbud.android/https/www.busbud.com/it/bus-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}",
    nl: "android-app://com.busbud.android/https/www.busbud.com/nl/bus-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}",
    pl: "android-app://com.busbud.android/https/www.busbud.com/pl/autobus-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}",
    pt: "android-app://com.busbud.android/https/www.busbud.com/pt/onibus-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}",
    "pt-pt":
      "android-app://com.busbud.android/https/www.busbud.com/pt-pt/autocarro-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}",
    ru: "android-app://com.busbud.android/https/www.busbud.com/ru/avtobus-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}",
    sv: "android-app://com.busbud.android/https/www.busbud.com/sv/buss-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}",
    tr: "android-app://com.busbud.android/https/www.busbud.com/tr/otobus-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}",
    zh: "android-app://com.busbud.android/https/www.busbud.com/zh/bus-{origin_name}-{destination_name}/r/{origin_geohash}-{destination_geohash}"
  },
  city: {
    de: "/de/bus-{name}/c/{geohash}",
    en: "/en/bus-{name}/c/{geohash}",
    "en-ca": "/en-ca/bus-{name}/c/{geohash}",
    "en-gb": "/en-gb/bus-{name}/c/{geohash}",
    es: "/es/autobus-{name}/c/{geohash}",
    "es-419": "/es-419/autobus-{name}/c/{geohash}",
    "es-mx": "/es-mx/autobus-{name}/c/{geohash}",
    fr: "/fr/bus-{name}/c/{geohash}",
    "fr-ca": "/fr-ca/bus-{name}/c/{geohash}",
    it: "/it/bus-{name}/c/{geohash}",
    nl: "/nl/bus-{name}/c/{geohash}",
    pl: "/pl/autobus-{name}/c/{geohash}",
    pt: "/pt/onibus-{name}/c/{geohash}",
    "pt-pt": "/pt-pt/autocarro-{name}/c/{geohash}",
    ru: "/ru/avtobus-{name}/c/{geohash}",
    sv: "/sv/buss-{name}/c/{geohash}",
    tr: "/tr/otobus-{name}/c/{geohash}",
    zh: "/zh/bus-{name}/c/{geohash}"
  },
  trains_to: {
    de: "/de/zug-{name}/ct/{geohash}",
    en: "/en/train-{name}/ct/{geohash}",
    "en-ca": "/en-ca/train-{name}/ct/{geohash}",
    "en-gb": "/en-gb/train-{name}/ct/{geohash}",
    es: "/es/tren-{name}/ct/{geohash}",
    "es-419": "/es-419/tren-{name}/ct/{geohash}",
    "es-mx": "/es-mx/tren-{name}/ct/{geohash}",
    fr: "/fr/train-{name}/ct/{geohash}",
    "fr-ca": "/fr-ca/train-{name}/ct/{geohash}",
    it: "/it/treno-{name}/ct/{geohash}",
    nl: "/nl/trein-{name}/ct/{geohash}",
    pl: "/pl/pociag-{name}/ct/{geohash}",
    pt: "/pt/trem-{name}/ct/{geohash}",
    "pt-pt": "/pt-pt/trem-{name}/ct/{geohash}",
    ru: "/ru/poezd-{name}/ct/{geohash}",
    sv: "/sv/vlak-{name}/ct/{geohash}",
    tr: "/tr/tren-{name}/ct/{geohash}",
    zh: "/zh/train-{name}/ct/{geohash}"
  },
  service_disruption: {
    de: "/de/reiseunterbrechungen-streiks/td/{country_code2}",
    en: "/en/travel-disruptions-strikes/td/{country_code2}",
    "en-ca": "/en-ca/travel-disruptions-strikes/td/{country_code2}",
    "en-gb": "/en-gb/travel-disruptions-strikes/td/{country_code2}",
    es: "/es/interrupciones-viaje-huelgas/td/{country_code2}",
    "es-419": "/es-419/interrupciones-viaje-huelgas/td/{country_code2}",
    "es-mx": "/es-mx/interrupciones-viaje-huelgas/td/{country_code2}",
    fr: "/fr/perturbations-voyage-greves/td/{country_code2}",
    "fr-ca": "/fr-ca/perturbations-voyage-greves/td/{country_code2}",
    it: "/it/interruzioni-viaggio-scioperi/td/{country_code2}",
    nl: "/nl/reisonderbrekingen-stakingen/td/{country_code2}",
    pl: "/pl/zaklocenia-podrozy-strajki/td/{country_code2}",
    pt: "/pt/interrupcoes-viagem-greves/td/{country_code2}",
    "pt-pt": "/pt-pt/interrupcoes-viagem-greves/td/{country_code2}",
    ru: "/ru/travel-disruptions-strikes/td/{country_code2}",
    sv: "/sv/reseavbrott-strejker/td/{country_code2}",
    tr: "/tr/seyahat-aksakliklari-grevler/td/{country_code2}",
    zh: "/zh/travel-disruptions-strikes/td/{country_code2}"
  },
  city_sem: {
    de: "/de/bus-{name}/c-sem/{geohash}",
    en: "/en/bus-{name}/c-sem/{geohash}",
    "en-ca": "/en-ca/bus-{name}/c-sem/{geohash}",
    "en-gb": "/en-gb/bus-{name}/c-sem/{geohash}",
    es: "/es/autobus-{name}/c-sem/{geohash}",
    "es-419": "/es-419/autobus-{name}/c-sem/{geohash}",
    "es-mx": "/es-mx/autobus-{name}/c-sem/{geohash}",
    fr: "/fr/bus-{name}/c-sem/{geohash}",
    "fr-ca": "/fr-ca/bus-{name}/c-sem/{geohash}",
    it: "/it/bus-{name}/c-sem/{geohash}",
    nl: "/nl/bus-{name}/c-sem/{geohash}",
    pl: "/pl/autobus-{name}/c-sem/{geohash}",
    pt: "/pt/onibus-{name}/c-sem/{geohash}",
    "pt-pt": "/pt-pt/autocarro-{name}/c-sem/{geohash}",
    ru: "/ru/avtobus-{name}/c-sem/{geohash}",
    sv: "/sv/buss-{name}/c-sem/{geohash}",
    tr: "/tr/otobus-{name}/c-sem/{geohash}",
    zh: "/zh/bus-{name}/c-sem/{geohash}"
  },
  sitemap: {
    routes: {
      index: "/{locale}/sitemap/wr/0",
      country: "/{locale}/sitemap/c/{country_code2}"
    },
    stops: {
      index: "/{locale}/sitemap/ws/0",
      country: "/{locale}/sitemap/cs/{country_code2}",
      city: "/{locale}/sitemap/is/{city_geohash}"
    },
    points_of_interest: {
      index: "/{locale}/sitemap/wpoi/0",
      country: "/{locale}/sitemap/cpoi/{country_code2}",
      city: "/{locale}/sitemap/ipoi/{city_geohash}"
    },
    cities: {
      index: "/{locale}/sitemap/wc/0",
      country: "/{locale}/sitemap/cc/{country_code2}"
    },
    trains_to: {
      index: "/{locale}/sitemap/wct/0",
      country: "/{locale}/sitemap/cct/{country_code2}"
    },
    partners: {
      index: "/{locale}/sitemap/wp/0"
    },
    service_disruptions: {
      index: "/{locale}/sitemap/td/0"
    }
  },
  sitemap_keyword: {
    routes: {
      index: "/{locale}/sitemap/{keyword}/wr/0",
      country: "/{locale}/sitemap/{keyword}/c/{country_code2}"
    },
    stops: {
      index: "/{locale}/sitemap/{keyword}/ws/0",
      country: "/{locale}/sitemap/{keyword}/cs/{country_code2}",
      city: "/{locale}/sitemap/{keyword}/is/{city_geohash}"
    },
    cities: {
      country: "/{locale}/sitemap/{keyword}/cc/{country_code2}"
    },
    partners: {
      index: "/{locale}/sitemap/{keyword}/wp/0"
    }
  },
  country: "/{locale}/country/{country_code2}",
  help: {
    de: "https://help.busbud.com/hc/de",
    en: "https://help.busbud.com/hc/en-us",
    "en-ca": "https://help.busbud.com/hc/en-us",
    "en-gb": "https://help.busbud.com/hc/en-us",
    es: "https://help.busbud.com/hc/es",
    "es-419": "https://help.busbud.com/hc/es",
    "es-mx": "https://help.busbud.com/hc/es",
    fr: "https://help.busbud.com/hc/fr",
    "fr-ca": "https://help.busbud.com/hc/fr",
    it: "https://help.busbud.com/hc/it",
    nl: "https://help.busbud.com/hc/en-us",
    pl: "https://help.busbud.com/hc/en-us",
    pt: "https://help.busbud.com/hc/pt-br",
    "pt-pt": "https://help.busbud.com/hc/pt-br",
    ru: "https://help.busbud.com/hc/ru",
    sv: "https://help.busbud.com/hc/en-us",
    tr: "https://help.busbud.com/hc/tr",
    zh: "https://help.busbud.com/hc/en-us"
  },
  search: {
    submit: "/{locale}/search/submit"
  },
  checkout: {
    index: "/{locale}/checkout",
    referral: "/{locale}/checkout/referral/{referral_cart_id}"
  },
  thank_you: {
    index: "/{locale}/thank-you/{purchase_id}"
  },
  purchase_unsuccessful: {
    index: "/{locale}/purchase-unsuccessful/{purchase_id}"
  },
  account: {
    logout: "/{locale}/auth/logout",
    loyalty_profile: "/{locale}/account/profile",
    passenger_profiles: "/{locale}/account/passenger-profiles",
    profile: "/{locale}/account/trips",
    settings: "/{locale}/account/settings",
    signin: "/{locale}/account/signin",
    signup: "/{locale}/account/signup",
    trip: "/{locale}/account/trips/{purchase_id}",
    trips: "/{locale}/account/trips",
    forgot_password: "/{locale}/account/forgot-password"
  },
  error404: "/{locale}/404",
  image: "https://busbud.imgix.net/",
  typeform_survey: {
    rating: {
      de: "https://busbudsurveys.typeform.com/to/W6fwAB",
      en: "https://busbudsurveys.typeform.com/to/iivPVl",
      "en-ca": "https://busbudsurveys.typeform.com/to/iivPVl",
      "en-gb": "https://busbudsurveys.typeform.com/to/iivPVl",
      es: "https://busbudsurveys.typeform.com/to/vhfT11",
      "es-419": "https://busbudsurveys.typeform.com/to/vhfT11",
      "es-mx": "https://busbudsurveys.typeform.com/to/vhfT11",
      fr: "https://busbudsurveys.typeform.com/to/SOwOHg",
      "fr-ca": "https://busbudsurveys.typeform.com/to/SOwOHg",
      it: "https://busbudsurveys.typeform.com/to/MJzxTh",
      nl: "https://busbudsurveys.typeform.com/to/kHrFq2",
      pl: "https://busbudsurveys.typeform.com/to/uCXpDI",
      pt: "https://busbudsurveys.typeform.com/to/gi7xDR",
      "pt-pt": "https://busbudsurveys.typeform.com/to/gi7xDR",
      ru: "", // FIXME
      sv: "https://busbudsurveys.typeform.com/to/UtazGL",
      tr: "https://busbudsurveys.typeform.com/to/wQsUf9",
      zh: "https://busbudsurveys.typeform.com/to/TLLEO1"
    }
  },
  terms: "/{locale}/terms/{ticket_type}/{departure_id}",
  third_party: {
    booking_com_search:
      "https://sp.booking.com/searchresults.html?aid={aid}&checkin={checkin_date}&checkout={checkout_date}&dest_id={dest_id}&dest_type=city&lang={lang}&selected_currency={currency}&label=device_id%3D{device_id}",
    booking_com_demand_api: "https://demandapi.booking.com/3.1"
  },
  google_maps: "https://www.google.com/maps/search/?api=1&query={lat},{lon}"
};

module.exports = link_templates;
