import { isString } from "lodash";

export function isNotNil<X>(x: X | null | undefined): x is X {
  return x !== null && x !== undefined;
}

type NonEmptyArray<X> = [X, ...X[]];

export function isNotEmpty<T>(
  x: T[] | T | null | undefined
): x is NonEmptyArray<T> {
  if (Array.isArray(x) || isString(x)) {
    return x.length > 0;
  }

  return isNotNil(x);
}
